
/* Carousel styles */
.custom-carousel {
  width: 100%;
}

.carousel-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70vh;
}

.carousel-image {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
}

.carousel-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 28%;
  height: 100%;
  background-color: aliceblue;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}
.carousel-text::before {
  content: '';
  position: absolute;
  top: 0;
  right: -120px; /* Adjust based on arrow size */
  width: 0;
  height: 0;
  border-top: 265px solid transparent; /* Adjust based on arrow size */
  border-bottom: 265px solid transparent; /* Adjust based on arrow size */
  border-left: 120px solid rgb(125, 35, 35); /* Adjust based on arrow size and color */
}

 
 
  .carousel-text::after {
    content: '';
    position: absolute;
    top: 0;
    right: -100px; /* Adjust based on arrow size */
    width: 0;
    height: 0;
    border-top: 265px solid transparent; /* Adjust based on arrow size */
    border-bottom: 265px solid transparent; /* Adjust based on arrow size */
    border-left: 100px solid transparent; /* Adjust based on arrow size */
    border-right: none; /* No right border */
    border-left-color: aliceblue; /* Adjust based on arrow color */
    background-color: transparent; /* No background */
  }
  
  .carousel-text1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 25%;
    height: 100%;
    background-color: rgb(125, 35, 35);
    color: aliceblue; /* Setting text color */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif; /* Setting Roboto font */
    font-weight: bold; /* Making the font bold */
    font-size: 24px; /* Adjust font size as needed */
  }
  

  .carousel-text1::before {
    content: '';
    position: absolute;
    top: 0;
    right: -100px; /* Adjust based on arrow size */
    width: 0;
    height: 0;
    border-top: 265px solid transparent; /* Adjust based on arrow size */
    border-bottom: 265px solid transparent; /* Adjust based on arrow size */
    border-left: 100px solid rgb(125, 35, 35); /* Adjust based on arrow size and color */
  }

  
  

/* Container styles */
.container {
  padding: 10px 20px; /* Adjust padding as needed */
  display: flex;
  justify-content: space-between;
  /*background-image: url('../assets/122.jpg');
  background-size: cover;
  background-position: center;*/
  background-color: #aa3030;

  background: linear-gradient(to right, #9e3d3dcc, #922525, #940000);
  
  
  
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Adjust as needed */
  grid-gap: 20px;
  margin-top: 20px;

}


/* Box styles */
.box {
  opacity: 0;
  transition: opacity 0.5s ease;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  transition: all 0.3s ease; /* Smooth transition on hover */
  border-radius: 20px;
  border:1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}
.box:hover {
  transform: scale(1.1); /* Scale on hover */
}

.image {
  width: 100%; /* Adjust width as needed */
  height: auto;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 20px;
  animation: zoomIn 1s ease;
}

.box-content {
  padding: 10px;
  animation: zoomIn 1s ease;
}

.heading-text {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  color: rgb(0, 0, 0);
  text-align: center; /* Aligning the text to the center */
  flex: 1; /* Allowing the text to take up remaining space */
}

.description {
  font-family: 'Roboto', sans-serif;
}

.link {
  display: inline-block;
  padding: 5px 10px;
  text-decoration: none;
  color: black;
  border: 2px solid black;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border:1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  margin-top: 10px;
}

/* Animation styles */
.box.show {
  opacity: 1;
}
/* Data Container 
.data-container {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  align-items: center;
}


.top-row {
  display: flex;
  justify-content: center;
  background-image: url('../assets/224.jpg');
  align-items: center;
  position: relative;
  width: 100%; 
}


.data-text {
  padding: 10px 0;
  width: 50%;
  font: 100 1rem Roboto;
  color: rgb(37, 150, 190);
  text-align: center;
  position: relative;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border:1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  margin-top: 10px;
}

.data-text::before,
.data-text::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background-color: rgba(185, 182, 182, 0.1); 
}

.data-text::before {
  top: 0;
}

.data-text::after {
  bottom: 0;
}


/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .grid-container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  .image {
    width: 100%;
  }

  .box {
    margin-bottom: 20px;
  }

  .data-text {
    font-size: 1rem; /* Adjust font size as needed */
  }
}
